.app {
  font-family: 'Arial', sans-serif;
  background-image: url('./components/png/background.png');
  background-color: rgba(255, 255, 255, 0.6);
  background-blend-mode: lighten;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  vertical-align: middle
}

.app-content {
  text-align: center;
  margin: 0 auto;
  border-bottom: 0px solid #626262;
}

h1 {
  font-size: 36px;
  color: #333;
}

.logo {
  width: 200px;
  margin: 30px auto;
}

.social-links {
  margin-top: 100px;
  margin-bottom: 40px;

}

.social-links a {
  display: inline-block;
  margin: 0 10px;
}

.social-links img {
  width: 40px;
  height: 40px;
}

.text {
  margin-top: 20%;
  font-size: 40px;
  color: #333;
}

.Login-app-footer {
  text-align: center;
  width: 100%;
  height: 7%;
  position: fixed;
  bottom: 0px;
  padding: 0px;
  font-size: 0.9em;
  margin: 0 auto;
  background: #d9d9d9;
  border-bottom: 0px solid #626262;
}

.Login-footer-links {
  display: flex;
  justify-content: center;
}

.Login-footer-links a {
  color: #4a68ff8e;
  text-decoration: none;
  margin: 1%;
  margin-top: 1.24471%;
}

.Login-footer-links a:hover {
  text-decoration: underline;
}

/* Стилизация текста "© CatheV" */
.Login-copyright-text {
  position: relative;
  top: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.social-links-vk {
  transform: scale(2);

  display: flex;
  margin-right: 5vh;
}

.social-links-tg {
  transform: scale(1.3);
  margin-left: 5vh;
}


@media (max-width: 768px) {
  .Login-app-footer {
    text-align: center;
    width: 100%;
    height: 7%;
    position: fixed;
    bottom: 0px;
    padding: 8vh;
    font-size: 0.9em;
    margin: 0 auto;
    background: #d9d9d9;
    border-bottom: 0px solid #626262;
  }

  .Login-footer-links {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    right: auto;
    left: auto;
    top: -40px;
  }

  .Login-footer-links a {
    color: #4a68ff8e;
    text-decoration: none;
    margin: 3%;
    margin-top: 1.24471%;
  }

  .Login-footer-links a:hover {
    text-decoration: underline;
  }

  /* Стилизация текста "© CatheV" */
.Login-copyright-text {
    position: relative;
    top: -60px;
  }
}